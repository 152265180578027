



























































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { mapGetters, mapState } from "vuex";
import vSelect from "vue-select";
import BigNumber from "bignumber.js";
import { formatDistance } from "date-fns";

import { Address, GasPrice, GasPriceInfo, IndexedCurrencyInfo } from "@/types";
import { CONTRACT } from "@/network";
import { timeout, shrinkedAddress } from "@/helpers";
import filtersMixin from "@/mixins/filters";

import state, { isLoadedState } from "@/contract";
import Popup, { TRANSACTION_STATUS } from "@/components/UI/Popup.vue";
import { PLACEHOLDER_CURRENCIES_INFO } from "@/coins";

// StartedRollingDice (bytes32 requestId, address user, uint256 seed)
// FinishedRollingDice (bytes32 requestId, uint256 dayTimestamp, uint256 diceFace)

// TODO: extract to types
type StartedRollingDiceEvent = {
    user: Address;
    userSeed: number;

    _requestId: string;
    _transactionHash: string;
    _blockNumber: number;
}

type FinishedRollingDiceEvent = {    
    diceFace: number;
    // raw: string;
    dayTimestamp: Date;
    dateFormatted: string;

    _requestId: string;
    _transactionHash: string;
    _blockNumber: number;
}

type RollData = {
    start?: StartedRollingDiceEvent;
    result?: FinishedRollingDiceEvent;
}

@Component({
    name: "Auction",
    components: { vSelect },
    mixins: [filtersMixin],
    methods: {
        shrinkedAddress,
    },
    data: () => ({
        coins: state.coins
    }),
    computed: {
        ...mapGetters({
            getGasPriceOptions: 'getGasPriceOptions',
            sigTokenBalance: 'getSigTokenBalance',
        }),
        ...mapState([
            'balanceChartData',
            'total',
            'fee',
            'adminFee',
        ])
    },
})
export default class Lottery extends Vue {
    public sigTokenBalance!: string;
    private coins!: IndexedCurrencyInfo[];

    private explorer = 'https://etherscan.io'
    private contractAddress = CONTRACT.lottery;

    private currencies = PLACEHOLDER_CURRENCIES_INFO
    private selectedBuyToken = this.currencies[0]
    private isAdvancedOptionsOpened = false
    private notAllowToBurn = false

    private currentPrice: BigNumber | null = null
    private burnAmount = ''
    private userSeed: number | null = null

    private balances: any = {}
    private adminBalances: any = []

    // dictionary requestId => RollData
    private rollsEvents: { [key: string]: StartedRollingDiceEvent } = {}
    private rollsResults: { [key: string]: FinishedRollingDiceEvent } = {}

    private rolls: { [key: string]: RollData } = {}
    private rollsSorted: RollData [] = []
    private isRollActive = false;
    private currentDayTimestamp: number | null = null;

    getGasPriceOptions!: GasPriceInfo[]
    private gasOptionsSelect = { label: '', value: new BigNumber(1e9) }

    get gasPrice (): GasPrice {
        return new BigNumber(this.gasOptionsSelect.value)
    }

    get timerUntilActive () {
        if (!this.currentDayTimestamp) { return null }
        // countdown until getCurrentDayTimestamp + 1 day
        return Math.floor((Date.now()/1000) - (this.currentDayTimestamp + 86400))
    }

    get accumulatedFees (): BigNumber {
        return Object.values(this.adminBalances).length
            ? Object.values(this.adminBalances).reduce((sum: BigNumber, { amount }: any) => sum.plus(amount), new BigNumber(0))
            : new BigNumber(NaN)
    }

    goToLiquidity() {
        this.$router.push('/deposit')
    }

    async mounted() {

        while (!state.web3 || !state.isReady) {
            // DON'T RENDER AT ALL
            console.log("waiting 1 sec")
            await timeout(1000);
        }

        // this.updateSetRandomSeed()
        this.fetchTokenBalances()
        this.fetchRolls()

        this.updateIsRollActive()
    }

    async fetchTokenBalances() {
        if (!isLoadedState(state)) { throw new Error("State Not Loaded yet") }

        try {
            const adminBalances = await Promise.all(
                this.coins.map(async (coin, i) => {
                    if (!isLoadedState(state)) { throw new Error("State Not Loaded yet") }

                    const amount = await state.swapContract.methods.admin_balances(i).call()

                    return {
                        amount: new BigNumber(amount).div(coin.precision),
                        symbol: coin.symbol
                    }
                })
            )

            console.log('admin balances', adminBalances)
            this.adminBalances = adminBalances

            // HACK: trigger recalculation
            this.$forceUpdate()
            console.log('fees', this.accumulatedFees)
        } catch (e) {
            console.error('error fetching admin balances', e)
        }
    }

    async fetchRolls() {
        if (!isLoadedState(state)) { throw new Error("State Not Loaded yet") }

        const GAME_PERIOD_SECONDS = 60 * 3; // usually 60*60*24;
        const DICE_SIZE = 10; // usually 42;

        const current = await state.lotteryContract.methods.getCurrentDayTimestamp().call();

        // TODO: use events instead, for reference see https://rinkeby.etherscan.io/tx/0x363e2c9b5751451e8256e784cbea34dcca640b3253ecaa8d2574ff6a23fae02c#eventlog
        const rolls = await Promise.all(Array(10).fill(null).map(async (_: any, index: number) => {
            if (!isLoadedState(state)) { throw new Error("State Not Loaded yet") }

            const result = await state.lotteryContract.methods.rolledDiceAtDay(current - GAME_PERIOD_SECONDS*index).call()

            const day = new Date((current - GAME_PERIOD_SECONDS*index)*1000);

            return {
                index,
                raw: result,
                day: day,
                fromNow: formatDistance(day, new Date(), { addSuffix: true }),
                dice: result % DICE_SIZE,
            }
        }))

        console.log('rolls', rolls)

        // Fetch last 10 events of each type, then match them by requestId

        const startedRollingDiceSub = state.lotteryContract.events.StartedRollingDice({}, { fromBlock: 0 })
        const finishedRollingDiceSub = state.lotteryContract.events.FinishedRollingDice({}, { fromBlock: 0 })

        startedRollingDiceSub.subscribe((err: any, result: any) => {
            // result is StartedRollingDice event object
            console.log('new rollsEvent started', result)

            const { requestId, user, seed } = result.returnValues

            this.rollsEvents[requestId] = {
                user: user,
                userSeed: seed,
                _requestId: requestId,
                _transactionHash: result.transactionHash,
                _blockNumber: result.blockNumber,
            }

            console.log('this.rollsEvents', this.rollsEvents)


            this.rolls[requestId] = {
                ...this.rolls[requestId] || {},
                start: this.rollsEvents[requestId],
            }

            // TODO: sort before saving
            this.rollsSorted = Object.values(this.rolls)
                .sort((a, b) => {
                    return (b.result?._blockNumber || b.start?._blockNumber || 0)
                         - (a.result?._blockNumber || a.start?._blockNumber || 0)
                })

            console.log('this.rollsSorted', this.rollsSorted)

        })

        finishedRollingDiceSub.subscribe((err: any, result: any) => {
            // result is FinishedRollingDice event object
            console.log('new rollsEvent finished', result)

            const { requestId, dayTimestamp, diceFace } = result.returnValues

            const timestamp = new Date(dayTimestamp * 1000)

            // console.log('timestamp', timestamp)
            // console.log('timestamp', formatDistance(timestamp, new Date(), { addSuffix: true }))

            this.rollsResults[requestId] = {
                dayTimestamp: timestamp,
                dateFormatted: formatDistance(timestamp, new Date(), { addSuffix: true }),

                diceFace: diceFace,
                // raw: diceFace,
                _requestId: requestId,
                _transactionHash: result.transactionHash,
                _blockNumber: result.blockNumber,
            }

            console.log('this.rollsResults', this.rollsResults)
            
            // this.rollsSorted[requestId].result = this.rollsResults[requestId]

            this.rolls[requestId] = {
                ...this.rolls[requestId] || {},
                result: this.rollsResults[requestId],
            }

            this.rollsSorted = Object.values(this.rolls)
                .sort((a, b) => {
                    return (b.result?._blockNumber || b.start?._blockNumber || 0)
                         - (a.result?._blockNumber || a.start?._blockNumber || 0)
                })

            console.log('this.rollsSorted', this.rollsSorted)
        })

    }

    async updateIsRollActive(): Promise<boolean> {
        if (!isLoadedState(state)) { throw new Error("State Not Loaded yet") }

        const currentDayTimestamp = await state.lotteryContract.methods.getCurrentDayTimestamp().call();
        
        const lastResult = await state.lotteryContract.methods.rolledDiceAtDay(currentDayTimestamp).call()

        this.currentDayTimestamp = Number(currentDayTimestamp)
        this.isRollActive = lastResult == 0

        return this.isRollActive

    }

    async updateSetRandomSeed () {
        if (!isLoadedState(state)) { throw new Error("State Not Loaded yet") }

        this.userSeed = Math.floor(Math.random() * 1024)

        console.log('user seed', this.userSeed)
    }

    async submitRoll () {
        if (!isLoadedState(state)) { throw new Error("State Not Loaded yet") }
        // if (this.notAllowToBurn) return

        // const gasPrice = this.gasPrice

        try {
            const result = state.lotteryContract.methods.rollTheDice(this.userSeed).send({
                from: state.defaultAccount,
                // gasPrice: gasPrice,
                gasLimit: 300_000,
            })

            console.log('result', result)
            // console.log('tx hash', result.transactionHash)

            result.on('receipt', (receipt: any) => {
                console.log('tx hash', receipt.transactionHash)
                this.$notify(Popup.tx.success(TRANSACTION_STATUS.diceRolled, receipt.transactionHash))
            })

            result.on('error', (err: any) => {
                console.error(err);
    
                this.$notify(Popup.plain.error(TRANSACTION_STATUS.wrong, `Error rolling dice: ${err.message}`));
            })

        } catch (err) {
            console.error(err);

            this.$notify(Popup.plain.error(TRANSACTION_STATUS.wrong, `Error rolling dice: ${err.message}`));
        }
    }

}
